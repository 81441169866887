import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'

import { useCaseToUseCaseType } from './generatedRelations'

const tableName = 'use_case_type'

export const useCaseTypeSchema = pgTable(
	tableName,
	{
		...standardFields,
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const useCaseTypeRelations = relations(
	useCaseTypeSchema,
	({ many, one }) => ({
		ownerId: one(userSchema, {
			fields: [useCaseTypeSchema.ownerId],
			references: [userSchema.id],
		}),
		tenantId: one(tenantSchema, {
			fields: [useCaseTypeSchema.tenantId],
			references: [tenantSchema.id],
		}),
		useCase: many(useCaseToUseCaseType),
	}),
)
