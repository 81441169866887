import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import { featureSchema } from './featureSchema'
import { tagToMetric } from './generatedRelations'
import { metricValueSchema } from './metricValueSchema'

const tableName = 'metric'

export const metricSchema = pgTable(
	tableName,
	{
		...standardFields,
		featureId: varchar('feature_id').references(() => featureSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const metricRelations = relations(metricSchema, ({ many, one }) => ({
	feature: one(featureSchema, {
		fields: [metricSchema.featureId],
		references: [featureSchema.id],
	}),
	metricValue: many(metricValueSchema),
	ownerId: one(userSchema, {
		fields: [metricSchema.ownerId],
		references: [userSchema.id],
	}),
	tag: many(tagToMetric),
	tenantId: one(tenantSchema, {
		fields: [metricSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
