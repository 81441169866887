import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'

export const industrySchema = pgTable(
	'industry',
	{
		...standardFields,
		vectorText,
	},
	(table) => tenantIdIndex('industry', table),
)

export const industryRelations = relations(industrySchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [industrySchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [industrySchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
