import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, timestamp } from 'drizzle-orm/pg-core'

import {
	eventToFeature,
	eventToPersona,
	tagToEvent,
} from './generatedRelations'

const tableName = 'event'

export const eventSchema = pgTable(
	tableName,
	{
		...standardFields,
		endTime: timestamp('end_time'),
		startTime: timestamp('start_time'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const eventRelations = relations(eventSchema, ({ many, one }) => ({
	feature: many(eventToFeature),
	ownerId: one(userSchema, {
		fields: [eventSchema.ownerId],
		references: [userSchema.id],
	}),
	persona: many(eventToPersona),
	tag: many(tagToEvent),
	tenantId: one(tenantSchema, {
		fields: [eventSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
