import { createId } from '@paralleldrive/cuid2'
import { sql } from 'drizzle-orm'
import {
	boolean,
	customType,
	pgTable,
	timestamp,
	uniqueIndex,
	varchar,
	type AnyPgColumn,
} from 'drizzle-orm/pg-core'

export const primaryId = {
	id: varchar('id')
		.$defaultFn(() => createId())
		.notNull()
		.primaryKey(),
}

export const createdAndUpdated = {
	createdAt: timestamp('createdAt')
		.default(sql`(CURRENT_TIMESTAMP)`)
		.notNull(),
	updatedAt: timestamp('updatedAt')
		.default(sql`(CURRENT_TIMESTAMP)`)
		.notNull(),
}

export const nameAndDescription = {
	description: varchar('description'),
	name: varchar('name').notNull(),
}

export const tenantSchema = pgTable(
	'tenant',
	{
		...primaryId,
		...createdAndUpdated,
		hostname: varchar('hostname'),
		name: varchar('name').notNull(),
	},
	(table) => {
		return {
			id: uniqueIndex('tenant_id').on(table.id, table.name),
		}
	},
)

export const tenantId = varchar('tenant_id')
	.notNull()
	.references(() => tenantSchema.id, {
		onDelete: 'cascade',
		onUpdate: 'cascade',
	})

export const userSchema = pgTable(
	'user',
	{
		...primaryId,
		email: varchar('email').notNull(),
		name: varchar('name'),
		username: varchar('username').notNull(),
		...createdAndUpdated,
		internal: boolean('internal').default(false),
		tenantId,
	},
	(table) => {
		return {
			emailKey: uniqueIndex('user_email_key').on(table.email),
			tenantIdKey: uniqueIndex('user_tenantIdKey').on(table.tenantId, table.id),
			usernameKey: uniqueIndex('user_username_key').on(table.username),
		}
	},
)

export const ownerId = varchar('owner_id')
	.notNull()
	.references(() => userSchema.id, { onDelete: 'cascade', onUpdate: 'cascade' })

export const userId = varchar('user_id')
	.notNull()
	.references(() => userSchema.id, { onDelete: 'cascade', onUpdate: 'cascade' })

const tsvector = customType<{
	config: { sources: [string] }
	data: string
}>({
	dataType(config) {
		if (config) {
			// const sources = config.sources.join(" || ' ' || ")
			const sources = config.sources[0]
			return `tsvector GENERATED ALWAYS AS (to_tsvector('english', ${sources})) STORED`
		} else {
			return `tsvector`
		}
	},
})

export const vectorText = tsvector('vector_text', {
	sources: ['name'],
})

export const standardFields = {
	...primaryId,
	...nameAndDescription,
	...createdAndUpdated,
	ownerId,
	tenantId,
	// vectorText,
}

export function tenantIdIndex(
	name: string,
	table: Record<string, AnyPgColumn>,
) {
	if (!('tenantId' in table) || !('id' in table)) {
		throw new Error('id or tenantId not found')
	}

	return {
		tenantIdKey: uniqueIndex(name + '_tenant_primary_id_key').on(
			table.tenantId,
			table.id,
		),
	}
}

export const isSuggested = boolean('is_suggested').default(false)
