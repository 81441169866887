import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, timestamp, varchar } from 'drizzle-orm/pg-core'
import { customerSchema } from './customerSchema'
import { tagToProject } from './generatedRelations'
import { taskSchema } from './taskSchema'

const tableName = 'project'

export const projectSchema = pgTable(
	tableName,
	{
		...standardFields,
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		endTime: timestamp('end_time'),
		startTime: timestamp('start_time'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const projectRelations = relations(projectSchema, ({ many, one }) => ({
	customer: one(customerSchema, {
		fields: [projectSchema.customerId],
		references: [customerSchema.id],
	}),
	ownerId: one(userSchema, {
		fields: [projectSchema.ownerId],
		references: [userSchema.id],
	}),
	tag: many(tagToProject),
	task: many(taskSchema),
	tenantId: one(tenantSchema, {
		fields: [projectSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
