import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'

import { materialToMaterialType, tagToMaterialType } from './generatedRelations'

const tableName = 'material_type' as const

export const materialTypeSchema = pgTable(
	tableName,
	{
		...standardFields,
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const materialTypeRelations = relations(
	materialTypeSchema,
	({ many, one }) => ({
		material: many(materialToMaterialType),
		ownerId: one(userSchema, {
			fields: [materialTypeSchema.ownerId],
			references: [userSchema.id],
		}),
		tag: many(tagToMaterialType),
		tenantId: one(tenantSchema, {
			fields: [materialTypeSchema.tenantId],
			references: [tenantSchema.id],
		}),
	}),
)
