import { relations } from 'drizzle-orm'
import { pgTable, text } from 'drizzle-orm/pg-core'

import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from './baseSchema.ts'

export const linkSchema = pgTable(
	'link',
	{
		...standardFields,
		type: text('type'),
		url: text('url'),
	},
	(table) => tenantIdIndex('link', table),
)

export const linkRelations = relations(linkSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [linkSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [linkSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
