import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { boolean, pgTable, text } from 'drizzle-orm/pg-core'

import {
	eventToFeature,
	materialToFeature,
	tagToFeature,
} from './generatedRelations'

const tableName = 'feature'

export const featureSchema = pgTable(
	tableName,
	{
		...standardFields,
		isNew: boolean('is_new'),
		publicDescription: text('public_description'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const featureRelations = relations(featureSchema, ({ many, one }) => ({
	event: many(eventToFeature),
	material: many(materialToFeature),
	ownerId: one(userSchema, {
		fields: [featureSchema.ownerId],
		references: [userSchema.id],
	}),
	tag: many(tagToFeature),
	tenantId: one(tenantSchema, {
		fields: [featureSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
