export const linkTypes = [
	['adminportal', 'Admin Portal'],
	['blog', 'Blog'],
	['community', 'Community'],
	['docs', 'Documentation'],
	['feedback', 'Feedback'],
	['events', 'Events'],
	['mainproduct', 'Main Product'],
	['status', 'Status Page'],
	['support', 'Support Portal'],
	['supportTicket', 'New Support Ticket'],
	['requestSession', 'Request a Session'],
	['releaseNotes', 'Release Notes'],
	['training', 'Training'],
	['wiki', 'Wiki'],
	['other', 'Other'],
] as const satisfies [[string, string], ...[string, string][]]

export function getAllLinkTypes() {
	return linkTypes.map(([id, name]) => ({
		id,
		name,
	}))
}
