import { omit } from '#app/utils/lodash.ts'
import { relations } from 'drizzle-orm'
import { pgTable, text } from 'drizzle-orm/pg-core'
import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from './baseSchema.ts'

const tableName = 'token'

export const tokenSchema = pgTable(
	tableName,
	{
		...omit(standardFields, ['name']),
		tokenHash: text('token_hash').notNull(),
		// expiration_date: timestamp('expiration_date').notNull(),
	},
	(table) => tenantIdIndex(tableName, table),
)

export const tokenRelations = relations(tokenSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [tokenSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [tokenSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
