import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'
import {
	tagToTraining,
	trainingToFeature,
	trainingToPersona,
} from './generatedRelations'

const tableName = 'training'

export const trainingSchema = pgTable(
	tableName,
	{
		...standardFields,
		url: varchar('url'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const trainingRelations = relations(trainingSchema, ({ many, one }) => ({
	feature: many(trainingToFeature),
	ownerId: one(userSchema, {
		fields: [trainingSchema.ownerId],
		references: [userSchema.id],
	}),
	persona: many(trainingToPersona),
	tag: many(tagToTraining),
	tenantId: one(tenantSchema, {
		fields: [trainingSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
