import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import { customerSchema } from './customerSchema'
import { tagToUseCase, useCaseToUseCaseType } from './generatedRelations'

const tableName = 'use_case'

export const useCaseSchema = pgTable(
	tableName,
	{
		...standardFields,
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const useCaseRelations = relations(useCaseSchema, ({ many, one }) => ({
	customer: one(customerSchema, {
		fields: [useCaseSchema.customerId],
		references: [customerSchema.id],
	}),
	ownerId: one(userSchema, {
		fields: [useCaseSchema.ownerId],
		references: [userSchema.id],
	}),
	tag: many(tagToUseCase),
	tenantId: one(tenantSchema, {
		fields: [useCaseSchema.tenantId],
		references: [tenantSchema.id],
	}),
	useCaseType: many(useCaseToUseCaseType),
}))
