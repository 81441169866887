import { omit } from '#app/utils/lodash.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'
import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from './baseSchema.ts'
import { customerSchema } from './customerSchema.ts'
import { customerTeamSchema } from './customerTeamSchema.ts'
import { eventSchema } from './eventSchema.ts'
import { featureSchema } from './featureSchema.ts'
import { industrySchema } from './industrySchema.ts'
import { linkSchema } from './linkSchema.ts'
import { materialSchema } from './materialSchema.ts'
import { materialTypeSchema } from './materialTypeSchema.ts'
import { metricSchema } from './metricSchema.ts'
import { personaSchema } from './personaSchema.ts'
import { projectSchema } from './projectSchema.ts'
import { roleSchema } from './roleSchema.ts'
import { trainingSchema } from './trainingSchema.ts'
import { useCaseSchema } from './useCaseSchema.ts'

const tableName = 'obj_role'

export const objRoleSchema = pgTable(
	tableName,
	{
		...omit(standardFields, ['ownerId']),
		modelName: varchar('model_name').notNull(),
		roleId: varchar('role_id').references(() => roleSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
	},
	(table) => {
		return {
			nameKey: tenantIdIndex(tableName, table),
		}
	},
)

export const objRoleRelations = relations(objRoleSchema, ({ many, one }) => ({
	objRoleAssignment: many(objRoleAssignmentSchema),
	role: one(roleSchema, {
		fields: [objRoleSchema.roleId],
		references: [roleSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [objRoleSchema.id],
		references: [tenantSchema.id],
	}),
}))

const objRoleAssignmentTableName = 'obj_role_assignment'

export const objRoleAssignmentSchema = pgTable(
	objRoleAssignmentTableName,
	{
		customerId: varchar('customer_id').references(() => customerSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		customerTeamId: varchar('customer_team_id').references(
			() => customerTeamSchema.id,
			{
				onDelete: 'cascade',
				onUpdate: 'cascade',
			},
		),
		eventId: varchar('event_id').references(() => eventSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		featureId: varchar('feature_id').references(() => featureSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		id: standardFields.id,
		industryId: varchar('industry_id').references(() => industrySchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		linkId: varchar('link_id').references(() => linkSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		materialId: varchar('material_id').references(() => materialSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		materialTypeId: varchar('material_type_id').references(
			() => materialTypeSchema.id,
			{
				onDelete: 'cascade',
				onUpdate: 'cascade',
			},
		),
		metricId: varchar('metric_id').references(() => metricSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		objRoleId: varchar('obj_role_id')
			.notNull()
			.references(() => objRoleSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
		personaId: varchar('persona_id').references(() => personaSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		projectId: varchar('project_id').references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
		trainingId: varchar('training_id').references(() => trainingSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		useCaseId: varchar('use_case_id').references(() => useCaseSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		userId: varchar('user_id')
			.notNull()
			.references(() => userSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
	},
	(table) => tenantIdIndex(tableName, table),
)

export const objRoleAssignmentRelations = relations(
	objRoleAssignmentSchema,
	({ one }) => ({
		customer: one(customerSchema, {
			fields: [objRoleAssignmentSchema.customerId],
			references: [customerSchema.id],
		}),
		objRole: one(objRoleSchema, {
			fields: [objRoleAssignmentSchema.objRoleId],
			references: [objRoleSchema.id],
		}),
		tenant: one(tenantSchema, {
			fields: [objRoleAssignmentSchema.userId],
			references: [tenantSchema.id],
		}),
		user: one(userSchema, {
			fields: [objRoleAssignmentSchema.userId],
			references: [userSchema.id],
		}),
	}),
)
