import { omit } from '#app/utils/lodash'
import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import { customerSchema } from './customerSchema'

const tableName = 'customer_login_allow'

export const customerLoginAllowSchema = pgTable(
	tableName,
	{
		...omit(standardFields, ['name', 'description']),
		customerId: varchar('customer_id').notNull(),
		emailDomain: varchar('email_domain').notNull(),
	},
	(table) => tenantIdIndex(tableName, table),
)

export const customerLoginAllowRelations = relations(
	customerLoginAllowSchema,
	({ one }) => ({
		customer: one(customerSchema, {
			fields: [customerLoginAllowSchema.customerId],
			references: [customerSchema.id],
		}),
		ownerId: one(userSchema, {
			fields: [customerLoginAllowSchema.ownerId],
			references: [userSchema.id],
		}),
		tenantId: one(tenantSchema, {
			fields: [customerLoginAllowSchema.tenantId],
			references: [tenantSchema.id],
		}),
	}),
)
