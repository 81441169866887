import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import { customerSchema } from './customerSchema'

const tableName = 'health_score'

export const healthScoreSchema = pgTable(
	tableName,
	{
		...standardFields,
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	(table) => tenantIdIndex(tableName, table),
)

export const healthScoreRelations = relations(healthScoreSchema, ({ one }) => ({
	customer: one(customerSchema, {
		fields: [healthScoreSchema.customerId],
		references: [customerSchema.id],
	}),
	ownerId: one(userSchema, {
		fields: [healthScoreSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [healthScoreSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
