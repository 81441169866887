import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import { customerSchema } from './customerSchema'
import { customerTeamToUseCase, tagToCustomerTeam } from './generatedRelations'

const tableName = 'customer_team'

export const customerTeamSchema = pgTable(
	tableName,
	{
		...standardFields,
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const customerTeamRelations = relations(
	customerTeamSchema,
	({ many, one }) => ({
		customer: one(customerSchema, {
			fields: [customerTeamSchema.customerId],
			references: [customerSchema.id],
		}),
		ownerId: one(userSchema, {
			fields: [customerTeamSchema.ownerId],
			references: [userSchema.id],
		}),
		tag: many(tagToCustomerTeam),
		tenantId: one(tenantSchema, {
			fields: [customerTeamSchema.tenantId],
			references: [tenantSchema.id],
		}),
		useCase: many(customerTeamToUseCase),
	}),
)
