import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'

import { customerLoginAllowSchema } from './customerLoginAllowSchema'
import { customerTeamSchema } from './customerTeamSchema'
import {
	customerToIndustry,
	customerToUseCase,
	tagToCustomer,
} from './generatedRelations'
import { healthScoreSchema } from './healthScoreSchema'
import { projectSchema } from './projectSchema'

export const customerSchema = pgTable(
	'customer',
	{
		...standardFields,
		vectorText,
	},
	(table) => tenantIdIndex('customer', table),
)

export const customerRelations = relations(customerSchema, ({ many, one }) => ({
	customerLoginAllow: many(customerLoginAllowSchema),
	customerTeam: many(customerTeamSchema),
	healthScore: many(healthScoreSchema),
	industry: many(customerToIndustry),
	ownerId: one(userSchema, {
		fields: [customerSchema.ownerId],
		references: [userSchema.id],
	}),
	project: many(projectSchema),
	tag: many(tagToCustomer),
	tenantId: one(tenantSchema, {
		fields: [customerSchema.tenantId],
		references: [tenantSchema.id],
	}),
	useCase: many(customerToUseCase),
}))
