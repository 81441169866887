import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'

import {
	materialToFeature,
	materialToMaterialType,
	materialToPersona,
	tagToMaterial,
} from './generatedRelations'

const tableName = 'material' as const

export const materialSchema = pgTable(
	tableName,
	{
		...standardFields,
		url: varchar('url'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const materialRelations = relations(materialSchema, ({ many, one }) => ({
	feature: many(materialToFeature),
	materialType: many(materialToMaterialType),
	ownerId: one(userSchema, {
		fields: [materialSchema.ownerId],
		references: [userSchema.id],
	}),
	persona: many(materialToPersona),
	tag: many(tagToMaterial),
	tenantId: one(tenantSchema, {
		fields: [materialSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
