import { relations } from 'drizzle-orm'
import { pgTable, primaryKey, varchar } from 'drizzle-orm/pg-core'
import { tenantSchema } from './baseSchema.ts'
import { customerSchema } from './customerSchema.ts'
import { customerTeamSchema } from './customerTeamSchema.ts'
import { eventSchema } from './eventSchema.ts'
import { featureSchema } from './featureSchema.ts'
import { industrySchema } from './industrySchema.ts'
import { materialSchema } from './materialSchema.ts'
import { materialTypeSchema } from './materialTypeSchema.ts'
import { metricSchema } from './metricSchema.ts'
import { personaSchema } from './personaSchema.ts'
import { projectSchema } from './projectSchema.ts'
import { tagSchema } from './tagSchema.ts'
import { trainingSchema } from './trainingSchema.ts'
import { useCaseSchema } from './useCaseSchema.ts'
import { useCaseTypeSchema } from './useCaseTypeSchema.ts'

export const customerToIndustry = pgTable(
	'customer_to_industry',
	{
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		industryId: varchar('industry_id')
			.notNull()
			.references(() => industrySchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.customerId, t.industryId] }),
	}),
)

export const customerToIndustryRelations = relations(customerToIndustry, ({ one }) => ({
	customer: one(customerSchema, {
		fields: [customerToIndustry.customerId],
		references: [customerSchema.id],
	}),
	industry: one(industrySchema, {
		fields: [customerToIndustry.industryId],
		references: [industrySchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [customerToIndustry.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const customerToUseCase = pgTable(
	'customer_to_use_case',
	{
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		useCaseId: varchar('use_case_id')
			.notNull()
			.references(() => useCaseSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.customerId, t.useCaseId] }),
	}),
)

export const customerToUseCaseRelations = relations(customerToUseCase, ({ one }) => ({
	customer: one(customerSchema, {
		fields: [customerToUseCase.customerId],
		references: [customerSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [customerToUseCase.tenantId],
		references: [tenantSchema.id],
	}),
	useCase: one(useCaseSchema, {
		fields: [customerToUseCase.useCaseId],
		references: [useCaseSchema.id],
	}),
}))

export const useCaseToUseCaseType = pgTable(
	'use_case_to_use_case_type',
	{
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		useCaseId: varchar('use_case_id')
			.notNull()
			.references(() => useCaseSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		useCaseTypeId: varchar('use_case_type_id')
			.notNull()
			.references(() => useCaseTypeSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.useCaseId, t.useCaseTypeId] }),
	}),
)

export const useCaseToUseCaseTypeRelations = relations(useCaseToUseCaseType, ({ one }) => ({
	tenant: one(tenantSchema, {
		fields: [useCaseToUseCaseType.tenantId],
		references: [tenantSchema.id],
	}),
	useCase: one(useCaseSchema, {
		fields: [useCaseToUseCaseType.useCaseId],
		references: [useCaseSchema.id],
	}),
	useCaseType: one(useCaseTypeSchema, {
		fields: [useCaseToUseCaseType.useCaseTypeId],
		references: [useCaseTypeSchema.id],
	}),
}))

export const customerTeamToUseCase = pgTable(
	'customer_team_to_use_case',
	{
		customerTeamId: varchar('customer_team_id')
			.notNull()
			.references(() => customerTeamSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		useCaseId: varchar('use_case_id')
			.notNull()
			.references(() => useCaseSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.customerTeamId, t.useCaseId] }),
	}),
)

export const customerTeamToUseCaseRelations = relations(customerTeamToUseCase, ({ one }) => ({
	customerTeam: one(customerTeamSchema, {
		fields: [customerTeamToUseCase.customerTeamId],
		references: [customerTeamSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [customerTeamToUseCase.tenantId],
		references: [tenantSchema.id],
	}),
	useCase: one(useCaseSchema, {
		fields: [customerTeamToUseCase.useCaseId],
		references: [useCaseSchema.id],
	}),
}))

export const materialToMaterialType = pgTable(
	'material_to_material_type',
	{
		materialId: varchar('material_id')
			.notNull()
			.references(() => materialSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		materialTypeId: varchar('material_type_id')
			.notNull()
			.references(() => materialTypeSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.materialId, t.materialTypeId] }),
	}),
)

export const materialToMaterialTypeRelations = relations(materialToMaterialType, ({ one }) => ({
	material: one(materialSchema, {
		fields: [materialToMaterialType.materialId],
		references: [materialSchema.id],
	}),
	materialType: one(materialTypeSchema, {
		fields: [materialToMaterialType.materialTypeId],
		references: [materialTypeSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [materialToMaterialType.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const materialToPersona = pgTable(
	'material_to_persona',
	{
		materialId: varchar('material_id')
			.notNull()
			.references(() => materialSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		personaId: varchar('persona_id')
			.notNull()
			.references(() => personaSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.materialId, t.personaId] }),
	}),
)

export const materialToPersonaRelations = relations(materialToPersona, ({ one }) => ({
	material: one(materialSchema, {
		fields: [materialToPersona.materialId],
		references: [materialSchema.id],
	}),
	persona: one(personaSchema, {
		fields: [materialToPersona.personaId],
		references: [personaSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [materialToPersona.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const materialToFeature = pgTable(
	'material_to_feature',
	{
		featureId: varchar('feature_id')
			.notNull()
			.references(() => featureSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		materialId: varchar('material_id')
			.notNull()
			.references(() => materialSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.materialId, t.featureId] }),
	}),
)

export const materialToFeatureRelations = relations(materialToFeature, ({ one }) => ({
	feature: one(featureSchema, {
		fields: [materialToFeature.featureId],
		references: [featureSchema.id],
	}),
	material: one(materialSchema, {
		fields: [materialToFeature.materialId],
		references: [materialSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [materialToFeature.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const eventToFeature = pgTable(
	'event_to_feature',
	{
		eventId: varchar('event_id')
			.notNull()
			.references(() => eventSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		featureId: varchar('feature_id')
			.notNull()
			.references(() => featureSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.eventId, t.featureId] }),
	}),
)

export const eventToFeatureRelations = relations(eventToFeature, ({ one }) => ({
	event: one(eventSchema, {
		fields: [eventToFeature.eventId],
		references: [eventSchema.id],
	}),
	feature: one(featureSchema, {
		fields: [eventToFeature.featureId],
		references: [featureSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [eventToFeature.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const eventToPersona = pgTable(
	'event_to_persona',
	{
		eventId: varchar('event_id')
			.notNull()
			.references(() => eventSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		personaId: varchar('persona_id')
			.notNull()
			.references(() => personaSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.eventId, t.personaId] }),
	}),
)

export const eventToPersonaRelations = relations(eventToPersona, ({ one }) => ({
	event: one(eventSchema, {
		fields: [eventToPersona.eventId],
		references: [eventSchema.id],
	}),
	persona: one(personaSchema, {
		fields: [eventToPersona.personaId],
		references: [personaSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [eventToPersona.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const trainingToPersona = pgTable(
	'training_to_persona',
	{
		personaId: varchar('persona_id')
			.notNull()
			.references(() => personaSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		trainingId: varchar('training_id')
			.notNull()
			.references(() => trainingSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.trainingId, t.personaId] }),
	}),
)

export const trainingToPersonaRelations = relations(trainingToPersona, ({ one }) => ({
	persona: one(personaSchema, {
		fields: [trainingToPersona.personaId],
		references: [personaSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [trainingToPersona.tenantId],
		references: [tenantSchema.id],
	}),
	training: one(trainingSchema, {
		fields: [trainingToPersona.trainingId],
		references: [trainingSchema.id],
	}),
}))

export const trainingToFeature = pgTable(
	'training_to_feature',
	{
		featureId: varchar('feature_id')
			.notNull()
			.references(() => featureSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		trainingId: varchar('training_id')
			.notNull()
			.references(() => trainingSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.trainingId, t.featureId] }),
	}),
)

export const trainingToFeatureRelations = relations(trainingToFeature, ({ one }) => ({
	feature: one(featureSchema, {
		fields: [trainingToFeature.featureId],
		references: [featureSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [trainingToFeature.tenantId],
		references: [tenantSchema.id],
	}),
	training: one(trainingSchema, {
		fields: [trainingToFeature.trainingId],
		references: [trainingSchema.id],
	}),
}))

export const tagToCustomer = pgTable(
	'tag_to_customer',
	{
		customerId: varchar('customer_id')
			.notNull()
			.references(() => customerSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.customerId] }),
	}),
)

export const tagToCustomerRelations = relations(tagToCustomer, ({ one }) => ({
	customer: one(customerSchema, {
		fields: [tagToCustomer.customerId],
		references: [customerSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToCustomer.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToCustomer.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToCustomerTeam = pgTable(
	'tag_to_customer_team',
	{
		customerTeamId: varchar('customer_team_id')
			.notNull()
			.references(() => customerTeamSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.customerTeamId] }),
	}),
)

export const tagToCustomerTeamRelations = relations(tagToCustomerTeam, ({ one }) => ({
	customerTeam: one(customerTeamSchema, {
		fields: [tagToCustomerTeam.customerTeamId],
		references: [customerTeamSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToCustomerTeam.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToCustomerTeam.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToEvent = pgTable(
	'tag_to_event',
	{
		eventId: varchar('event_id')
			.notNull()
			.references(() => eventSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.eventId] }),
	}),
)

export const tagToEventRelations = relations(tagToEvent, ({ one }) => ({
	event: one(eventSchema, {
		fields: [tagToEvent.eventId],
		references: [eventSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToEvent.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToEvent.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToFeature = pgTable(
	'tag_to_feature',
	{
		featureId: varchar('feature_id')
			.notNull()
			.references(() => featureSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.featureId] }),
	}),
)

export const tagToFeatureRelations = relations(tagToFeature, ({ one }) => ({
	feature: one(featureSchema, {
		fields: [tagToFeature.featureId],
		references: [featureSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToFeature.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToFeature.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToMaterial = pgTable(
	'tag_to_material',
	{
		materialId: varchar('material_id')
			.notNull()
			.references(() => materialSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.materialId] }),
	}),
)

export const tagToMaterialRelations = relations(tagToMaterial, ({ one }) => ({
	material: one(materialSchema, {
		fields: [tagToMaterial.materialId],
		references: [materialSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToMaterial.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToMaterial.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToMaterialType = pgTable(
	'tag_to_material_type',
	{
		materialTypeId: varchar('material_type_id')
			.notNull()
			.references(() => materialTypeSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.materialTypeId] }),
	}),
)

export const tagToMaterialTypeRelations = relations(tagToMaterialType, ({ one }) => ({
	materialType: one(materialTypeSchema, {
		fields: [tagToMaterialType.materialTypeId],
		references: [materialTypeSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToMaterialType.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToMaterialType.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToMetric = pgTable(
	'tag_to_metric',
	{
		metricId: varchar('metric_id')
			.notNull()
			.references(() => metricSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.metricId] }),
	}),
)

export const tagToMetricRelations = relations(tagToMetric, ({ one }) => ({
	metric: one(metricSchema, {
		fields: [tagToMetric.metricId],
		references: [metricSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToMetric.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToMetric.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToProject = pgTable(
	'tag_to_project',
	{
		projectId: varchar('project_id')
			.notNull()
			.references(() => projectSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.projectId] }),
	}),
)

export const tagToProjectRelations = relations(tagToProject, ({ one }) => ({
	project: one(projectSchema, {
		fields: [tagToProject.projectId],
		references: [projectSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToProject.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToProject.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToUseCase = pgTable(
	'tag_to_use_case',
	{
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		useCaseId: varchar('use_case_id')
			.notNull()
			.references(() => useCaseSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.useCaseId] }),
	}),
)

export const tagToUseCaseRelations = relations(tagToUseCase, ({ one }) => ({
	tag: one(tagSchema, {
		fields: [tagToUseCase.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToUseCase.tenantId],
		references: [tenantSchema.id],
	}),
	useCase: one(useCaseSchema, {
		fields: [tagToUseCase.useCaseId],
		references: [useCaseSchema.id],
	}),
}))

export const tagToTraining = pgTable(
	'tag_to_training',
	{
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		trainingId: varchar('training_id')
			.notNull()
			.references(() => trainingSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.trainingId] }),
	}),
)

export const tagToTrainingRelations = relations(tagToTraining, ({ one }) => ({
	tag: one(tagSchema, {
		fields: [tagToTraining.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToTraining.tenantId],
		references: [tenantSchema.id],
	}),
	training: one(trainingSchema, {
		fields: [tagToTraining.trainingId],
		references: [trainingSchema.id],
	}),
}))

