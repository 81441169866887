import { customerLoginAllowSchema } from '#db/schema/customerLoginAllowSchema.ts'
import { customerSchema } from '#db/schema/customerSchema.ts'
import { customerTeamSchema } from '#db/schema/customerTeamSchema.ts'
import { eventSchema } from '#db/schema/eventSchema.ts'
import { featureSchema } from '#db/schema/featureSchema.ts'
import {
	customerTeamToUseCase,
	customerToIndustry,
	eventToFeature,
	eventToPersona,
	materialToFeature,
	materialToMaterialType,
	materialToPersona,
	tagToCustomer,
	tagToCustomerTeam,
	tagToEvent,
	tagToFeature,
	tagToMaterial,
	tagToMaterialType,
	tagToMetric,
	tagToProject,
	tagToTraining,
	tagToUseCase,
	trainingToFeature,
	trainingToPersona,
	useCaseToUseCaseType,
} from '#db/schema/generatedRelations.ts'
import { healthScoreSchema } from '#db/schema/healthScoreSchema.ts'
import { industrySchema } from '#db/schema/industrySchema.ts'
import { linkSchema } from '#db/schema/linkSchema.ts'
import { materialSchema } from '#db/schema/materialSchema.ts'
import { materialTypeSchema } from '#db/schema/materialTypeSchema.ts'
import { metricSchema } from '#db/schema/metricSchema.ts'
import { metricValueSchema } from '#db/schema/metricValueSchema.ts'
import { objRoleSchema } from '#db/schema/objRoleSchema.js'
import { personaSchema } from '#db/schema/personaSchema.ts'
import { projectSchema } from '#db/schema/projectSchema.ts'
import { roleSchema } from '#db/schema/roleSchema.js'
import { tagSchema } from '#db/schema/tagSchema.ts'
import { taskSchema } from '#db/schema/taskSchema.ts'
import { tokenSchema } from '#db/schema/tokenSchema.js'
import { trainingSchema } from '#db/schema/trainingSchema.js'
import { useCaseSchema } from '#db/schema/useCaseSchema.ts'
import { useCaseTypeSchema } from '#db/schema/useCaseTypeSchema.ts'
import { z } from 'zod'
import { linkTypes } from './link-utils.ts'
import { omit } from './lodash.ts'
import { modelNames } from './modelNames.ts'
import {
	DATE,
	OPTIONAL_DATE,
	genProps,
	hasMany,
	hasOne,
	idList,
	manyToMany,
	schemas,
	type Model,
} from './modelUtils.ts'

export const models = {
	customer: {
		...genProps('customer'),
		drizzleSchema: customerSchema,
		hasObjRoles: true,
		helpText: 'Customers are the organizations that use your product',
		icon: 'building',
		relations: {
			...hasMany('customerTeam'),
			...hasMany('project'),
			...hasMany('useCase'),
			...manyToMany('industry', customerToIndustry),
			...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			customerTeamIds: idList,
			industryIds: idList,
			tagIds: idList,
			useCaseIds: idList,
		}),
		starable: true,
	},
	customerLoginAllow: {
		...genProps('customerLoginAllow'),
		drizzleSchema: customerLoginAllowSchema,
		helpText:
			"The customer's email domain that is allowed to login to the customer portal",
		icon: 'envelope-closed',
		relations: {
			...hasOne('customer'),
		},
		schema: z.object({
			customerId: z.string(),
			emailDomain: z.string(),
			id: schemas.id,
		}),
	},
	customerTeam: {
		...genProps('customerTeam'),
		drizzleSchema: customerTeamSchema,
		hasObjRoles: true,
		helpText: 'Customer teams are the groups of people at your customers',
		icon: 'component',
		relations: {
			...hasOne('customer'),
			...manyToMany('useCase', customerTeamToUseCase),
			...manyToMany('tag', tagToCustomerTeam),
		},
		schema: z.object({
			...schemas,
			customerId: z.string(),
			tagIds: idList,
			useCaseIds: idList,
		}),
	},

	event: {
		...genProps('event'),
		drizzleSchema: eventSchema,
		hasObjRoles: true,
		helpText: 'Events that you host for your customers',
		icon: 'ticket',
		relations: {
			...manyToMany('persona', eventToPersona),
			...manyToMany('feature', eventToFeature),
			...manyToMany('tag', tagToEvent),
		},
		schema: z.object({
			...schemas,
			endTime: DATE,
			featureIds: idList,
			personaIds: idList,
			startTime: DATE,
			tagIds: idList,
		}),
		starable: true,
	},
	feature: {
		...genProps('feature'),
		drizzleSchema: featureSchema,
		hasObjRoles: true,
		helpText:
			'Features are the functionality that your customers use in your product',
		icon: 'box',
		relations: {
			...manyToMany('event', eventToFeature),
			...manyToMany('material', materialToFeature),
			...manyToMany('tag', tagToFeature),
		},
		schema: z.object({
			...schemas,
			eventIds: idList,
			isNew: z.enum(['true', 'false']).default('false'),
			materialIds: idList,
			publicDescription: z.string().optional(),
			tagIds: idList,
		}),
		starable: true,
	},
	healthScore: {
		...genProps('healthScore'),
		drizzleSchema: healthScoreSchema,
		helpText:
			'Health scores are a measure of how well your customers are doing',
		icon: 'heart-pulse',
		relations: {
			...hasOne('customer'),
		},
		schema: z.object({
			...omit(schemas, ['name']),
			customerId: z.string(),
			name: z.enum(['red', 'yellow', 'green']),
		}),
	},
	industry: {
		...genProps('industry', { namePlural: 'industries' }),
		drizzleSchema: industrySchema,
		hasObjRoles: true,
		helpText: 'Industries are the verticals that your customers operate in',
		icon: 'factory',
		relations: {
			...manyToMany('customer', customerToIndustry),
			...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			customerIds: idList,
			tagIds: idList,
		}),
	},
	link: {
		...genProps('link', { isAdmin: true }),
		drizzleSchema: linkSchema,
		helpText: 'Tags are used to categorize customers.',
		icon: 'link-2',
		relations: {},
		schema: z.object({
			...schemas,
			type: z.enum([linkTypes[0][0], ...linkTypes.slice(1).map((a) => a[0])]),
			url: z.string(),
		}),
	},
	material: {
		...genProps('material'),
		drizzleSchema: materialSchema,
		hasObjRoles: true,
		helpText:
			'Materials are the content that you use to educate your customers',
		icon: 'file-text',
		relations: {
			...manyToMany('feature', materialToFeature),
			...manyToMany('materialType', materialToMaterialType),
			...manyToMany('persona', materialToPersona),
			...manyToMany('tag', tagToMaterial),
		},
		schema: z.object({
			...schemas,
			featureIds: idList,
			materialTypeIds: idList,
			personaIds: idList,
			tagIds: idList,
			url: z.string().optional(),
		}),
		starable: true,
	},
	materialType: {
		...genProps('materialType', { isAdmin: true }),
		drizzleSchema: materialTypeSchema,
		helpText: 'Material types are the categories that materials fall into',
		icon: 'file-text',
		relations: {
			...manyToMany('material', materialToMaterialType),
			...manyToMany('tag', tagToMaterialType),
		},
		schema: z.object({
			...schemas,
			materialIds: idList,
			tagIds: idList,
		}),
	},
	metric: {
		...genProps('metric'),
		drizzleSchema: metricSchema,
		hasObjRoles: true,
		helpText: 'Metrics are numerical values that you track over time',
		icon: 'tally-5',
		relations: {
			...hasOne('feature'),
			...manyToMany('tag', tagToMetric),
		},
		schema: z.object({
			...schemas,
			featureId: z.string().optional(),
			tagIds: idList,
		}),
		starable: true,
	},
	metricValue: {
		...genProps('metricValue'),
		drizzleSchema: metricValueSchema,
		helpText: 'Metrics are values that you track over time',
		icon: 'tally-5',
		relations: {
			...hasOne('metric'),
			...hasOne('customer'),
		},
		schema: z.object({
			customerId: z.string().optional(),
			id: schemas.id,
			metricId: z.string(),
		}),
	},
	objRole: {
		...genProps('objRole', { isAdmin: true }),
		drizzleSchema: objRoleSchema,
		helpText: 'Roles that users can have on an object',
		icon: 'square-user-round',
		relations: {},
		schema: z.object({
			description: schemas.description,
			id: schemas.id,
			modelName: z.enum(modelNames), // technically should be more specific than this,
			name: schemas.name,
		}),
	},
	persona: {
		...genProps('persona'),
		drizzleSchema: personaSchema,
		hasObjRoles: true,
		helpText: 'Personas are the types of users that use your product',
		icon: 'square-user-round',
		relations: {
			...manyToMany('material', materialToPersona),
			// ...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			materialIds: idList,
		}),
		starable: true,
	},
	project: {
		...genProps('project'),
		drizzleSchema: projectSchema,
		hasObjRoles: true,
		helpText: 'Projects are a collection of tasks for a customer',
		icon: 'square-kanban',
		relations: {
			...hasOne('customer'),
			...hasMany('task'),
			...manyToMany('tag', tagToProject),
		},
		schema: z.object({
			...schemas,
			customerId: z.string(),
			endTime: OPTIONAL_DATE,
			startTime: OPTIONAL_DATE,
			tagIds: idList,
		}),
		starable: true,
	},
	role: {
		...genProps('role', { isAdmin: true }),
		drizzleSchema: roleSchema,
		helpText: 'User roles',
		icon: 'square-user-round',
		relations: {},
		schema: z.object({
			...omit(schemas, ['newTags']),
		}),
	},
	tag: {
		...genProps('tag'),
		drizzleSchema: tagSchema,
		helpText: 'Tags are used to categorize customers.',
		relations: {
			...manyToMany('customer', tagToCustomer),
			...manyToMany('customerTeam', tagToCustomerTeam),
			...manyToMany('event', tagToEvent),
			...manyToMany('feature', tagToFeature),
			...manyToMany('material', tagToMaterial),
			...manyToMany('metric', tagToMetric),
			...manyToMany('project', tagToProject),
			...manyToMany('training', tagToTraining),
			...manyToMany('useCase', tagToUseCase),
		},
		schema: z.object({ ...schemas }),
	},
	task: {
		...genProps('task'),
		drizzleSchema: taskSchema,
		helpText: 'Tasks are the individual items in a project',
		icon: 'list-checks',
		relations: {
			...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			completed: z.boolean().default(false),
			endTime: OPTIONAL_DATE,
			projectId: z.string(),
			startTime: OPTIONAL_DATE,
		}),
	},
	token: {
		...genProps('token', { isAdmin: true }),
		drizzleSchema: tokenSchema,
		hasObjRoles: false,
		helpText: 'API tokens for integrations',
		icon: 'log-in',
		relations: {},
		schema: z.object({
			id: schemas.id.optional(),
			tokenHash: z.string(),
		}),
		starable: false,
	},
	training: {
		...genProps('training'),
		drizzleSchema: trainingSchema,
		hasObjRoles: true,
		helpText: 'Training is the content that you use to educate your customers',
		icon: 'graduation-cap',
		relations: {
			...manyToMany('feature', trainingToFeature),
			...manyToMany('persona', trainingToPersona),
			...manyToMany('tag', tagToTraining),
		},
		schema: z.object({
			...schemas,
			featureIds: idList,
			personaIds: idList,
			tagIds: idList,
			url: z.string().optional(),
		}),
		starable: true,
	},
	useCase: {
		...genProps('useCase'),
		drizzleSchema: useCaseSchema,
		hasObjRoles: true,
		helpText:
			'Use cases are the scenarios that your customers use your product for',
		icon: 'newspaper',
		relations: {
			...hasOne('customer'),
			...manyToMany('useCaseType', useCaseToUseCaseType),
			...manyToMany('tag', tagToUseCase),
		},
		schema: z.object({
			...schemas,
			customerId: z.string(),
			tagIds: idList,
			useCaseTypeIds: idList,
		}),
		starable: true,
	},
	useCaseType: {
		...genProps('useCaseType', { isAdmin: true }),
		drizzleSchema: useCaseTypeSchema,
		helpText: 'Use case types are the categories that use cases fall into',
		icon: 'newspaper',
		relations: {
			...manyToMany('useCase', useCaseToUseCaseType),
		},
		schema: z.object({
			...schemas,
			useCaseIds: idList,
		}),
	},
	// userEngagement: {
	// 	...genProps('userEngagement'),
	// 	drizzleSchema: userEngagementSchema,
	// 	hasObjRoles: true,
	// 	helpText:
	// 		'User Engagement keeps the info about the #DAU, #UniqueUsers in last 30 days, top Users info at your customers',
	// 	icon: 'tally-5',
	// 	relations: {
	// 		...hasOne('customer'),
	// 	},
	// 	schema: z.object({
	// 		...schemas,
	// 		customerId: z.string(),
	// 		dau30: z.bigint(),
	// 		numUniqueUsers30: z.bigint(),
	// 		topUsers: z.array(z.string()),
	// 	}),
	// },
} as const satisfies { [K in (typeof modelNames)[number]]: Model<K> }
