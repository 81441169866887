import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { boolean, pgTable, timestamp, varchar } from 'drizzle-orm/pg-core'

import { projectSchema } from './projectSchema'

const tableName = 'task'

export const taskSchema = pgTable(
	tableName,
	{
		...standardFields,
		completed: boolean('completed'),
		endTime: timestamp('end_time'),
		projectId: varchar('project_id')
			.notNull()
			.references(() => projectSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		startTime: timestamp('start_time'),
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const taskRelations = relations(taskSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [taskSchema.ownerId],
		references: [userSchema.id],
	}),
	project: one(projectSchema, {
		fields: [taskSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [taskSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
