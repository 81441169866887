import { omit } from '#app/utils/lodash'
import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import {
	doublePrecision,
	pgTable,
	timestamp,
	varchar,
} from 'drizzle-orm/pg-core'

import { customerSchema } from './customerSchema'
import { metricSchema } from './metricSchema'

const tableName = 'metric_value'

export const metricValueSchema = pgTable(
	tableName,
	{
		...omit(standardFields, ['name', 'description']),
		customerId: varchar('customer_id').references(() => customerSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		eventTime: timestamp('event_time'),
		metricId: varchar('metric_id')
			.notNull()
			.references(() => metricSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		numericalValue: doublePrecision('numerical_value'),
	},
	(table) => tenantIdIndex(tableName, table),
)

export const metricValueRelations = relations(metricValueSchema, ({ one }) => ({
	customer: one(customerSchema, {
		fields: [metricValueSchema.customerId],
		references: [customerSchema.id],
	}),
	metric: one(metricSchema, {
		fields: [metricValueSchema.metricId],
		references: [metricSchema.id],
	}),
	ownerId: one(userSchema, {
		fields: [metricValueSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [metricValueSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
