import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'

import { eventToPersona, materialToPersona } from './generatedRelations'

const tableName = 'persona'

export const personaSchema = pgTable(
	tableName,
	{
		...standardFields,
		vectorText,
	},
	(table) => tenantIdIndex(tableName, table),
)

export const personaRelations = relations(personaSchema, ({ many, one }) => ({
	event: many(eventToPersona),
	material: many(materialToPersona),
	ownerId: one(userSchema, {
		fields: [personaSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [personaSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
